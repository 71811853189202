/*  "COLORS" STYLES */

body {
    color: $accent-color-3;
    background-color: $lightgrey;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $accent-color-1;
}
input {
    color: $accent-color-1;
}
a {
    color: $accent-color-1;
}
a.btn span {
    color: white;
}
a:hover,
a:active,
a:focus {
    color: $accent-color-2;
}
header {
    img {
        max-width: 150px;
    }
}

header,
footer {
    background-color: #ffffff;
}
.bar-color-top {
    height: 20px;
    background-color: $accent-color-1;
}
//overlay disable for form step 1
.booking-step1 {
    position: relative;
    /* .disable{
        display: none;
     } */
    .disable:before {
        content: '';
        display: block;
        position: absolute;
        left:0; right: 0; bottom: 0; top: 0;
        background-color: rgba(255, 255, 255, 0.83);
        z-index: 1;
    }
    button.disable {
        display: none;
    }
}
.textarea-observations {
    width: 100%;
}
.link-modify {
    color: $success;
    svg {
        color: $success;
    }
    
}


.summary-content {
    background-color: #efefef;
    padding: 1rem;
    margin-top: 1rem;
    h3 {
        font-size: 28px;
        font-weight: bold;
        color: #000;
    }
    span {
        font-weight: bold;
        color:#000;
    }

}
.btn-primary {
    background-color: $accent-color-1;
    border-color: $accent-color-1;
}
svg {
    color: $accent-color-1
}
.summary-page {
    .data-information {
        background-color:#fff;
        h6 {
            span  {
                color: #000
            }
    
        }
    }
}
.tab-content {
    background-color: #fff;
    padding: 16px;
}